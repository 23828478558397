import React, { useState } from "react";

//Paginate
import ReactPaginate from "react-paginate";
import "./pagination.css";

const AttendenceTable = ({
  data,
  rowsPerPage,
  dateToday,
  localDateOnly,
  setSearchDateState,
}) => {
  // for pagination
  const [employees] = useState(data.slice(0));
  const [pageNumber, setPageNumber] = useState(0);
  const pagesVisited = pageNumber * rowsPerPage;
  const [pageCount] = useState(Math.ceil(data.length / rowsPerPage));

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const tableHeadings = [
    "Name",
    "In Time",
    "Out Time",
    "Lunch Out",
    "Lunch In",
    "Hours Worked",
  ];

  return (
    <div className="px-4 py-2 my-6">
      <div className="mb-6 flex lg:flex-row flex-col justify-between items-center">
        <h2 className=" font-bold">Date Today = {dateToday}</h2>
        <div className="flex items-center gap-2">
          <label className="font-bold" htmlFor="date">
            Select Date :{" "}
          </label>
          <input
            className="px-4 py-2 border-2 border-gray-600 rounded-lg"
            type="date"
            name="date"
            min="2022-11-17"
            max={localDateOnly}
            onChange={(e) => {
              setSearchDateState(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="flex flex-col ">
        <table className="table-auto mb-6">
          <thead>
            <tr className="font-bold font-serif text-xs uppercase">
              {tableHeadings &&
                tableHeadings.map((element) => {
                  return (
                    <th
                      scope="col"
                      className="px-5 py-5 border-b-2 border-gray-600 bg-gray-400 text-left  text-gray-900 tracking-wider"
                    >
                      {element}
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {employees.length > 0 ? (
              <>
                {data &&
                  employees
                    .slice(pagesVisited, pagesVisited + rowsPerPage)
                    .map((val) => {
                      return (
                        <tr
                          key={val.id}
                          className="border border-gray-300 font-semibold px-4 py-2"
                        >
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            {val.firstname}&nbsp;{val.lastname}
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            {val.Office_In ? val.Office_In : "-----"}
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            {val.Office_Out ? val.Office_Out : "-----"}
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            {val.Lunch_Out ? val.Lunch_Out : "-----"}
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            {val.Lunch_In ? val.Office_In : "-----"}
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                           {"-----"}
                          </td>
                        </tr>
                      );
                    })}
              </>
            ) : (
              <>
                {data &&
                  data
                    .slice(pagesVisited, pagesVisited + rowsPerPage)
                    .map((val) => {

                      const Time = new Date();
                      const HourNow = parseInt(Time.getHours());
                      const MinNow = parseInt(Time.getMinutes());
                      const Office_In_hour = val.Office_In ? parseInt(val.Office_In.substring(0,2)): 0 ;
                      const Office_In_minute = val.Office_In ? parseInt(val.Office_In.substring(3,5)): 0 ;
                      const Office_In = Office_In_hour + (Office_In_minute/60);
                      const Office_Out_hour = val.Office_Out ? parseInt(val.Office_Out.substring(0,2)): HourNow ;
                      const Office_Out_minute = val.Office_Out ? parseInt(val.Office_Out.substring(3,5)): MinNow ;
                      const Office_Out = Office_Out_hour + (Office_Out_minute/60);
                      const Lunch_In_hour = val.Lunch_In ? parseInt(val.Lunch_In.substring(0,2)): 0 ;
                      const Lunch_In_minute = val.Lunch_In ? parseInt(val.Lunch_In.substring(3,5)): 0 ;
                      const Lunch_In = Lunch_In_hour + (Lunch_In_minute/60);
                      const Lunch_Out_hour = val.Lunch_Out ? parseInt(val.Lunch_Out.substring(0,2)): 0 ;
                      const Lunch_Out_minute = val.Lunch_Out ? parseInt(val.Lunch_Out.substring(3,5)): 0 ;
                      const Lunch_Out = Lunch_Out_hour + (Lunch_Out_minute/60);

                      const LunchTime = Lunch_In !== 0 && Lunch_Out !== 0 ? Lunch_In - Lunch_Out : 0 ;

                      const Total_Working_Hours = Office_Out - Office_In - LunchTime;
                      return (
                        <tr
                          key={val.id}
                          className="border border-gray-300 bg-gray-100 hover:bg-white text-gray-700"
                        >
                          <td className="px-6 py-4 font-medium">
                            {val.firstname}&nbsp;{val.lastname}
                          </td>
                          <td className="px-6 py-4 font-medium">
                            {val.Office_In ? val.Office_In : "-----"}
                          </td>
                          <td className="px-6 py-4 font-medium">
                            {val.Office_Out ? val.Office_Out : "-----"}
                          </td>
                          <td className="px-6 py-4 font-medium">
                            {val.Lunch_Out ? val.Lunch_Out : "-----"}
                          </td>
                          <td className="px-6 py-4 font-medium">
                            {val.Lunch_In ? val.Lunch_In : "-----"}
                          </td>
                          <td className="px-6 py-4 font-medium">
                          {Total_Working_Hours.toFixed(2)} Hours
                          </td>
                        </tr>
                      );
                    })}
              </>
            )}
          </tbody>
        </table>

        {/* <TableFooter range={range} slice={slice} setPage={setPage} page={page} /> */}
        {employees.length > 0 ? (
          <ReactPaginate
            previousLabel={"<<"}
            nextLabel={">>"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        ) : (
          <ReactPaginate
            previousLabel={"<<"}
            nextLabel={">>"}
            pageCount={data.length / rowsPerPage}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        )}
      </div>
    </div>
  );
};

export default AttendenceTable;
