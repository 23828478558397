import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { authContext } from "../../Components/Hooks/AuthContext";
import { menuContext } from "../../Components/Hooks/MenuContext";

const Portal = () => {
  const { hamBurger } = useContext(menuContext);
  const { authState } = useContext(authContext);

  const { id } = useParams();

  console.log(id);
  const UserId = parseInt(id);

  const [latitude , setLattitude] = useState(27.6704163);
  const [longitude , setLongitude] = useState(85.3239504);
  const [isIn, setIsIn] = useState(false);
  const [isOut, setIsOut] = useState(true);
  const [isLunchOut, setIsLunchOut] = useState(true);
  const [isLunchIn, setisLunchIn] = useState(true);
  // const { coords, isGeolocationAvailable, isGeolocationEnabled } =
  //   useGeolocated({
  //       positionOptions: {
  //           enableHighAccuracy: true,
  //       },
  //       userDecisionTimeout: 5000,
  //   });

  // useEffect(() => {   

  // if(!isGeolocationAvailable)  {
  //   window.alert("Your browser does not support Geolocation")
  // } else {
  //   if(!isGeolocationEnabled){
  //     window.alert("Geolocation is not enabled")
  //   }else {
  //     if(coords){
  //       setLattitude(coords.latitude);
  //       setLongitude(coords.longitude);
  //     }
      
  //   }
  // }
  // },[isGeolocationAvailable ,coords ,isGeolocationEnabled ])
  const dateToday = new Date();
    
    const localDay = dateToday.getDate();
    const localMonth = dateToday.getMonth();
    const localYear = dateToday.getFullYear();
    const localWeekDay = dateToday.getDay();

    const weekDays = ['Sunday','Monday','Tuesday','Wednesday','Thrusday','Friday','Saturday'];
    const localDate = localDay + '/' + (localMonth + 1 ) + '/' + localYear + ',   ' + weekDays[localWeekDay];

  const geolocationAPI = navigator.geolocation;
  const getUserCoordinates = () => {
    if (!geolocationAPI) {
      window.alert('Geolocation API is not available in your browser!')
    } else {
      geolocationAPI.getCurrentPosition((position) => {
        const { coords } = position;
        console.log(coords);
        if(coords.latitude < 27.61 && coords.latitude > 27.72){
          setLattitude(0);
        }
        if(coords.longitude < 85.30 && coords.longitude > 85.34){
          setLongitude(0);
        }
      }, (error) => {
        window.alert('Something went wrong getting your position!')
      })
    }
  }
    getUserCoordinates();

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      let date = new Date();
      const dateToday = date.toUTCString();
      // find Out if the employee is inside already
      await axios
        .get(
          `https://hrmbackend.vjconsultinggroup.com/employees/attendence/in/${UserId}/${dateToday}`,
          {
            headers: {
              accessToken: localStorage.getItem("authtoken"),
            },
          }
        )
        .then((res) => {
          if (res.data.isIn === true) {
            setIsIn(true);
            setIsOut(false);
            setIsLunchOut(false);
          }
        });
        // find Out if the employee is out already
      await axios
        .get(
          `https://hrmbackend.vjconsultinggroup.com/employees/attendence/out/${UserId}/${dateToday}`,
          {
            headers: {
              accessToken: localStorage.getItem("authtoken"),
            },
          }
        )
        .then((res) => {
          if (res.data.isOut === true) {
            setIsOut(true);
            setIsLunchOut(true);
            setisLunchIn(true);
          }
        });
        // find out employee is out for lunch
        await axios
        .get(
          `https://hrmbackend.vjconsultinggroup.com/employees/attendence/lunchOut/${UserId}/${dateToday}`,
          {
            headers: {
              accessToken: localStorage.getItem("authtoken"),
            },
          }
        )
        .then((res) => {
          if (res.data.isLunchOut === true) {
            setIsLunchOut(true);
            setisLunchIn(false);
          }
        });
        //find out is employee back from lunch
        await axios
        .get(
          `https://hrmbackend.vjconsultinggroup.com/employees/attendence/lunchIn/${UserId}/${dateToday}`,
          {
            headers: {
              accessToken: localStorage.getItem("authtoken"),
            },
          }
        )
        .then((res) => {
          if (res.data.isLunchIn === true) {
            setisLunchIn(true)
          }
        });
    };
    fetchData();
  }, [UserId]);

  const handelOfficeIn = () => {
    let date = new Date();
    const dateToday = date.toUTCString();
    const h = date.getHours();
    const m = date.getMinutes();
    const TimeNow = h + ":" + m;

    if (window.confirm("Are Yot Sure?")) {
      axios
        .post(
          `https://hrmbackend.vjconsultinggroup.com/employees/attendence/in/${UserId}/${dateToday}`,
          { Office_In: TimeNow ,
             latitude , longitude },
          {
            headers: {
              accessToken: localStorage.getItem("authtoken"),
            },
          }
        )
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            setIsIn(true);
            setIsOut(false);
            setIsLunchOut(false);
          }
        });
    }
  };
  const handelOfficeOut = () => {
    let date = new Date();
    const dateToday = date.toUTCString();
    const h = date.getHours();
    const m = date.getMinutes();
    const TimeNow = h + ":" + m;

    if (window.confirm("Are Yot Sure?")) {
      axios
        .post(
          `https://hrmbackend.vjconsultinggroup.com/employees/attendence/out/${UserId}/${dateToday}`,
          { Office_Out: TimeNow ,
            latitude , longitude},
          {
            headers: {
              accessToken: localStorage.getItem("authtoken"),
            },
          }
        )
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            setIsOut(true);
            setIsLunchOut(true);
            setisLunchIn(true);
          }
        });
    }
  };

  const handelLunchOut = () => {
    let date = new Date();
    const dateToday = date.toUTCString();
    const h = date.getHours();
    const m = date.getMinutes();
    const TimeNow = h + ":" + m;

    if (window.confirm("Are Yot Sure?")) {
      axios
        .post(
          `https://hrmbackend.vjconsultinggroup.com/employees/attendence/lunchOut/${UserId}/${dateToday}`,
          { Lunch_Out: TimeNow,
            latitude , longitude },
          {
            headers: {
              accessToken: localStorage.getItem("authtoken"),
            },
          }
        )
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            setIsLunchOut(true);
            setisLunchIn(false);
          }
        });
    }
  };
  const handelLunchIn = () => {
    let date = new Date();
    const dateToday = date.toUTCString();
    const h = date.getHours();
    const m = date.getMinutes();
    const TimeNow = h + ":" + m;

    if (window.confirm("Are Yot Sure?")) {
      axios
        .post(
          `https://hrmbackend.vjconsultinggroup.com/employees/attendence/lunchIn/${UserId}/${dateToday}`,
          { Lunch_In: TimeNow,
            latitude , longitude },
          {
            headers: {
              accessToken: localStorage.getItem("authtoken"),
            },
          }
        )
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            setisLunchIn(true);
          }
        });
    }
  };
  

  
  return (
    <>
      {authState && authState.status === true && authState.id === UserId ? (
        <div
          className={
            hamBurger
              ? "fixed top-[75px] right-[5px] left-[100px] transition-all"
              : "fixed top-[75px] right-[5px] left-[300px] transition-all"
          }
        >
          <div className="flex flex-col lg:flex-row items-center py-6">
          <h1 className="text-center font-extrabold lg:text-xl text-sm font-serif uppercase tracking-wider lg:w-1/2">
            Hello!{" "}
            <span className="first-letter:uppercase text-green-800">{authState.name}</span>
          </h1>
          <h2 className="text-center font-extrabold lg:text-xl text-sm font-serif uppercase tracking-wider lg:w-1/2">Date Today = {localDate}</h2>
          </div>
          
          <h2 className="text-center font-extrabold lg:text-2xl text-xl py-2 font-serif uppercase tracking-wider bg-gray-600 text-green-500 border-2 border-black">For Office In And Out</h2>
          <div className="flex flex-col lg:flex-row justify-between items-center gap-10 mb-10">
            <div className="lg:w-1/2 flex flex-col justify-center items-center py-5 ">
              <h2 className="text-center font-bold font-serif uppercase tracking-wider underline mb-2">
                Office In Time
              </h2>
              <button
                disabled={isIn === true ? true : false}
                onClick={handelOfficeIn}
                className={
                  isIn === true
                    ? "bg-gray-600 text-gray-50 px-4 py-2 rounded-lg cursor-not-allowed"
                    : "bg-green-600 text-gray-50 px-4 py-2 rounded-lg hover:bg-green-800"
                }
              >
                Punch In
              </button>
            </div>
            <div className="lg:w-1/2 flex flex-col justify-center items-center">
              <h2 className="text-center font-bold font-serif uppercase tracking-wider underline mb-2">
                Office Out Time
              </h2>
              <button
                disabled={isOut === true ? true : false}
                onClick={handelOfficeOut}
                className={
                  isOut === true
                    ? "bg-gray-600 text-gray-50 px-4 py-2 rounded-lg cursor-not-allowed"
                    : "bg-red-600 text-gray-50 px-4 py-2 rounded-lg hover:bg-red-800"
                }
              >
                Punch Out
              </button>
            </div>
          </div>
          <h2 className="text-center font-extrabold lg:text-2xl text-xl py-2 font-serif uppercase tracking-wider bg-gray-600 text-green-500  border-2 border-black">For Lunch In And Out</h2>
          <div className="flex flex-col lg:flex-row justify-between items-center gap-10 mb-10">
            <div className="lg:w-1/2 flex flex-col justify-center items-center py-5">
              <h2 className="text-center font-bold font-serif uppercase tracking-wider underline mb-2">
                Out For Lunch
              </h2>
              <button
                disabled={isLunchOut === true ? true : false}
                onClick={handelLunchOut}
                className={
                  isLunchOut === true
                    ? "bg-gray-600 text-gray-50 px-4 py-2 rounded-lg cursor-not-allowed"
                    : "bg-green-600 text-gray-50 px-4 py-2 rounded-lg hover:bg-green-800"
                }
              >
                Punch Out
              </button>
            </div>
            <div className="lg:w-1/2 flex flex-col justify-center items-center">
              <h2 className="text-center font-bold font-serif uppercase tracking-wider underline mb-2">
                In From Lunch 
              </h2>
              <button
                disabled={isLunchIn === true ? true : false}
                onClick={handelLunchIn}
                className={
                  isLunchIn === true
                    ? "bg-gray-600 text-gray-50 px-4 py-2 rounded-lg cursor-not-allowed"
                    : "bg-red-600 text-gray-50 px-4 py-2 rounded-lg hover:bg-red-800"
                }
              >
                Punch In
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Portal;
