import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { authContext } from "../../Components/Hooks/AuthContext";
import { menuContext } from "../../Components/Hooks/MenuContext";

const Index = () => {
  const { hamBurger } = useContext(menuContext);
  const { authState } = useContext(authContext);
  return (
    <>
      {authState && authState.status === true ? (
        <div
          className={
            hamBurger
              ? "fixed top-[75px] right-[5px] left-[100px] transition-all"
              : "fixed top-[75px] right-[5px] left-[300px] transition-all"
          }
        >
          <h1 className="text-center font-extrabold lg:text-2xl text-xl mb-10 font-serif uppercase tracking-wider">
            Welcome {authState.name} to VJ Consulting Employee Management Portal
          </h1>
          <div className="flex items-center justify-center">
            <Link
              to={`/attendence/${authState.id}`}
              className="bg-green-600 text-gray-50 px-4 py-2 rounded-lg hover:bg-green-800"
            >
              Get In
            </Link>
          </div>
        </div>
      ) : (
        null
      )}
    </>
  );
};

export default Index;
