import { React, useContext } from "react";
import DataPage from "../../Components/Attendence/DataPage";
import { menuContext } from "../../Components/Hooks/MenuContext";

const Daily = () => {
  const { hamBurger } = useContext(menuContext);

  return (
    <>
      <div
        className={
          hamBurger
            ? "fixed top-[75px] right-[5px] left-[100px] transition-all"
            : "fixed top-[75px] right-[5px] left-[300px] transition-all"
        }
      >
         <h1 className='text-center font-bold uppercase font-serif text-2xl tracking-widest'>Daily Attendence</h1>
         <div>
            <DataPage />
         </div>
      </div>
    </>
  );
};
export default Daily;
