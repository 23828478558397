import React, { useEffect, useState } from "react";

//Axios Import
import axios from "axios";

import AttendenceTable from "./AttendenceTable";

const DataPage = () => {

    const [attendenceRecord, setAttendenceRecord] = useState([]);
    
    const dateToday = new Date();
    const searchDate = dateToday.toUTCString();
    
    const localDay = dateToday.getDate();
    const localMonth = dateToday.getMonth();
    const localYear = dateToday.getFullYear();
    const localWeekDay = dateToday.getDay();
    
    const weekDays = ['Sunday','Monday','Tuesday','Wednesday','Thrusday','Friday','Saturday'];
    const localDate = localDay + '-' + (localMonth + 1 ) + '-' + localYear + ',   ' + weekDays[localWeekDay];
    const localDateOnly = localYear + '-' + (localMonth + 1 ) + '-' +  localDay  
    
    const [searchDateState , setSearchDateState] = useState(searchDate);

    useEffect(() => {
     
        const fetchData = async () => {
          await axios
            .get(`https://hrmbackend.vjconsultinggroup.com/employees/attendence/all/${searchDateState}`, {
              headers: {
                accessToken: localStorage.getItem("authtoken"),
              },
            })
            .then((response) => {
              if(response.data.error) {
                window.alert(response.data.error);
              } else {              
                setAttendenceRecord(response.data);
              console.log(response.data);
              }
            })
            .catch((error) => {
              // API call failed
              window.alert(error);
            });
        };
        fetchData();
      }, [searchDateState]);

  return (
    <div>
    {attendenceRecord && attendenceRecord ? <AttendenceTable data={attendenceRecord} rowsPerPage={8} dateToday={localDate} localDateOnly={localDateOnly} setSearchDateState={setSearchDateState}/> : "loading.."}
  </div>
  )
}

export default DataPage