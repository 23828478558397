import axios from "axios";
import { React, useContext, useEffect, useState } from "react";
import { menuContext } from "../../Components/Hooks/MenuContext";

const DashBoard = () => {
  const { hamBurger } = useContext(menuContext);
  const [initialCount ,setInitialCount] = useState(null);

  useEffect(() => {
   axios.get('https://hrmbackend.vjconsultinggroup.com/dashboard/initialCount',{
    headers: {
      accessToken: localStorage.getItem("authtoken"),
    },
   }).then((res) => {
    setInitialCount(res.data);
   })
  }, []);

  return (
    <>
      <div
        className={
          hamBurger
            ? "fixed top-[75px] right-[5px] left-[100px] transition-all"
            : "fixed top-[75px] right-[5px] left-[300px] transition-all"
        }
      >
        <div>
          <div className="py-4 px-6 grid lg:grid-cols-3 grid-cols-1 gap-6">
            <div className="shadow-lg rounded-xl bg-gray-400">
              <div className="flex flex-col p-4 text-gray-900 gap-4">
                <h2 className="text-center font-bold text-2xl tracking-wide uppercase">
                  Employees
                </h2>
                <p className="text-center font-semibold text-xl tracking-wide uppercase">
                 {initialCount && initialCount.EmployeeCount}
                </p>
              </div>
            </div>
            <div className="shadow-lg rounded-xl bg-gray-400">
              <div className="flex flex-col p-4 text-gray-900 gap-4">
                <h2 className="text-center font-bold text-2xl tracking-wide uppercase">
                  Projects
                </h2>
                <p className="text-center font-semibold text-xl tracking-wide uppercase">
                {initialCount && initialCount.ProjectCount}
                </p>
              </div>
            </div>
            <div className="shadow-lg rounded-xl bg-gray-400">
              <div className="flex flex-col p-4 text-gray-900 gap-4">
                <h2 className="text-center font-bold text-2xl tracking-wide uppercase">
                  Admins
                </h2>
                <p className="text-center font-semibold text-xl tracking-wide uppercase">
                {initialCount && initialCount.AdminCount}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashBoard;
