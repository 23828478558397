import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { menuContext } from "../../Components/Hooks/MenuContext";

const AddProject = () => {
  const { hamBurger } = useContext(menuContext);

  const [projectName, setProjectName] = useState(null);
  const [projects, setProjects] = useState(null);

  const [showEdit, setShowEdit] = useState({
    status: false,
    projectId: null,
  });

  const fetchData = async () => {
    await axios
      .get("https://hrmbackend.vjconsultinggroup.com/projects/all-projects", {
        headers: {
          accessToken: localStorage.getItem("authtoken"),
        },
      })
      .then((res) => {
        if (res.data.error) {
          window.alert(res.data.error);
        } else {
          setProjects(res.data);
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const handelAddProject = () => {
    if (projectName) {
      axios
        .post(
          "https://hrmbackend.vjconsultinggroup.com/projects/add-project",
          { Project_Name: projectName },
          {
            headers: {
              accessToken: localStorage.getItem("authtoken"),
            },
          }
        )
        .then((res) => {
          if (res.data.error) {
            window.alert(res.data.error);
          } else {
            window.alert(res.data.message);
            fetchData();
          }
        });
    } else {
      window.alert("Project name is required");
    }
  };

  return (
    <>
      <div
        className={
          hamBurger
            ? "fixed top-[75px] right-[5px] left-[100px] transition-all"
            : "fixed top-[75px] right-[5px] left-[300px] transition-all"
        }
      >
        <h1 className="text-3xl text-center font-extrabold font-serif uppercase mt-4">
          Add Project
        </h1>
        <div className="flex flex-col my-4">
          <div className="lg:w-1/2 px-4 py-2 mb-6">
            <div className="flex flex-col justify-center">
              <label
                htmlFor="project"
                className="mb-2 font-bold uppercase text-xl"
              >
                Project Name :
              </label>
              <div className="flex justify-between items-center gap-5">
                <input
                  name="project"
                  type="text"
                  className="p-2 border-2 border-black w-3/4"
                  placeholder="Enter Project Name"
                  onChange={(e) => {
                    setProjectName(e.target.value);
                  }}
                />
                <button
                  onClick={handelAddProject}
                  type="button"
                  className="w-1/4 px-4 py-2 bg-green-600 hover:bg-green-800 rounded-sm uppercase text-gray-100 font-bold"
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <div className="px-4 py-2 mb-6">
            <div className="flex flex-col">
              <h2 className="text-xl font-bold uppercase mb-2">
                Our Projects :
              </h2>
              <table className="table-auto">
                <thead>
                  <tr className="font-bold font-serif text-xs uppercase">
                    <th className="px-5 py-5 border-b-2 border-gray-600 bg-gray-400 text-left  text-gray-900 tracking-wider">
                      S.N.
                    </th>
                    <th className="px-5 py-5 border-b-2 border-gray-600 bg-gray-400 text-left  text-gray-900 tracking-wider">
                      Project Name
                    </th>
                    <th className="px-5 py-5 border-b-2 border-gray-600 bg-gray-400 text-left  text-gray-900 tracking-wider">
                      Start Date
                    </th>
                    <th className="px-5 py-5 border-b-2 border-gray-600 bg-gray-400 text-left  text-gray-900 tracking-wider">
                      Status
                    </th>
                    <th className="px-5 py-5 border-b-2 border-gray-600 bg-gray-400 text-left  text-gray-900 tracking-wider">
                      End Date
                    </th>
                    <th className="px-5 py-5 border-b-2 border-gray-600 bg-gray-400 text-left  text-gray-900 tracking-wider">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {projects &&
                    projects.map((element, index) => {
                      return (
                        <tr key={index} className="font-semibold px-4 py-2">
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            {element.id}.
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            {element.Project_Name}
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            {element.Started_On}
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            {element.status}
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            {element.Completed_On}
                          </td>
                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm flex items-center">
                            <button
                              type="button"
                              onClick={() => {
                                setShowEdit({
                                  status: true,
                                  projectId: element.id,
                                });
                              }}
                              className="px-4 py-2 bg-green-600 hover:bg-green-800 text-white rounded-sm"
                            >
                              Edit
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showEdit.status === true ? 
      <>
      <div className="md:pt-12 md:mb-6 fixed inset-0 z-50 outline-none focus:outline-none flex justify-center ">
        <div className="flex bg-white flex-col">
          {showEdit.projectId}
          <button onClick={() => {setShowEdit(
          {
            status: false,
            projectId: null
          }
        )}} 
        className="bg-red-600 px-4 py-2 text-white rounded-sm">close</button>
        </div>
        
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
      </> : null}
    </>
  );
};

export default AddProject;
